import React from "react"

import {
  Layout,
  Container,
  SEO,
  Sheet,
  Subscription,
  ThinContainer,
} from "../components/common"

import "./privacy.css"

export default ({
  pageContext: { totalItems, stats, defaultGradient, footerGradient },
}) => (
  <Layout
    title="Privacy Policy"
    totalItems={totalItems}
    stats={stats}
    defaultGradient={defaultGradient}
    footerGradient={footerGradient}
  >
    <Container>
      <SEO title="Privacy" />
      <Sheet>
        <div className="privacy">
          <ThinContainer>
            <div className="privacy-details">
              <p>
                Your privacy is critically important to us. At Codioful, we have
                a few fundamental principles:
              </p>
              <ul>
                <li>
                  We are thoughtful about the personal information we ask you to
                  provide and the personal information that we collect about you
                  through the operation of our services.
                </li>
                <li>
                  We store personal information for only as long as we have a
                  reason to keep it.
                </li>
                <li>
                  We aim to make it as simple as possible for you to control
                  what information on your website is shared publicly (or kept
                  private), indexed by search engines, and permanently deleted.
                </li>
                <li>
                  We help protect you from overreaching government demands for
                  your personal information.
                </li>
                <li>
                  We aim for full transparency on how we gather, use, and share
                  your personal information.
                </li>
              </ul>
              <p> </p>
              <p>
                Below is our Privacy Policy, which incorporates and clarifies
                these principles.
              </p>
              <p>
                <em>
                  This privacy policy has been adopted from{" "}
                  <a
                    href="https://automattic.com/privacy/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Automattic’s Privacy Policy
                  </a>{" "}
                  and the license is Attribution-ShareAlike 4.0 International
                  (CC BY-SA 4.0).
                </em>
              </p>
            </div>
            <div className="privacy-policy">
              <h4>Who We Are and What This Policy Covers</h4>
              <p>
                Codioful ("i", "us", "we", or "our") operates the
                https://codioful.com website (hereinafter referred to as the
                "Site", "Website", or "Service").
              </p>
              <p>
                This page informs you of our policies regarding the collection,
                use, and disclosure of personal data when you use our Service
                and the choices you have associated with that data.
              </p>
              <p>
                We use your data to provide and improve the Service. By using
                the Service, you agree to the collection and use of information
                in accordance with this policy.
              </p>
              <p>
                Below we explain how we collect, use, and share information
                about you, along with the choices that you have with respect to
                that information.
              </p>
            </div>
          </ThinContainer>
          <div className="privacy-data">
            <h2>How And What Information We Collect About You</h2>
            <ThinContainer>
              <p>
                We collect information in three ways: if and when you provide
                information to us, automatically through operating our Services,
                and from outside sources. Let’s go over the information that we
                collect.
              </p>
              <h3>Information You Provide To Us Voluntarily </h3>
              <p>
                While using our Service, we collect personally identifiable
                information (PII) that you provide to us such as name, email
                address via contact form, email newsletter form, survey etc. We
                also store a copy of our communications to operate and improve
                our Service. You may visit our Site, and use our Service
                anonymously. We collect these information if you provide your
                information voluntarily, otherwise some Site or Service related
                functions, activities and interactions may not work properly.
              </p>
              <h3>Information We Collect Automatically</h3>
              <p>
                <strong>Log Information:</strong> Like most online service
                providers, we follow a standard procedure of using log files
                when you visit our Site. The information collected by log files
                include internet protocol (IP) addresses, web browsers, internet
                service providers (ISP), mobile devices, and hosting/servers
                typically make available, such as the browser type, unique
                device identifiers, language preference, the date and time of
                access, operating system, mobile network, and demographic
                information.
              </p>
              <p>
                <strong>Usage Information:</strong> We collect information how
                the Service is accessed and used. This usage information
                includes, your device, screen size, browser type, browser
                version, IP address, name of cellular network, device
                manufacturer, the pages of our Service that you visit, time and
                date of your visit, the time spent on those pages, referring
                site, exit pages, number of clicks, and other diagnostic
                informations. We use this information to, for example, provide
                our Service to you, as well as get insights on how people use
                our Services, so we can make our Service better.
              </p>
              <p>
                <strong>Location Information:</strong> We may determine the
                approximate location of your device from your IP address. We
                collect and use this information to calculate several data such
                as when and how many people visit our Service from certain
                geographic regions. This helps us to create specific features,
                changes and improvements to our Service, for example, if we see
                lots of arabic people visit our Service then we can add arabic
                language to make our Service more accessible for those arabic
                peoples.
              </p>
              <p>
                <strong>Cookies:</strong> Like any other website, our Site uses
                'cookies'. A cookie is a string of information sent to your
                browser from our Site and stored on your device, and your
                browser provides to our Site each time you return. These cookies
                are used to store information including your preferences, the
                pages on the Site you accessed or visited. The information is
                used to optimize your experience by customizing our Website
                content based on your browser type and/or other information. You
                can instruct your browser to refuse all cookies or to indicate
                when a cookie is being sent. However, if you do not accept
                cookies, you may not be able to use some portions of our
                Service.
              </p>
              <p>
                <strong>Pixel Tags And Other Technologies:</strong> Other
                tracking technologies are also used such as pixel tags (also
                known as web beacons), scripts to collect and track information.
                These are small blocks of code placed on websites and emails. We
                use other technologies like pixel tags to help us identify and
                track visitors, usage, and access preferences for our Services,
                as well as track and understand email campaign effectiveness and
                to deliver targeted ads.
              </p>
              <p>
                <strong>Other Sources:</strong> We may also get information,
                such as a mailing address, from third party services about
                individuals who are not yet our users (…but we hope will be!),
                which we may use, for example, for marketing and advertising
                purposes about our service.
              </p>
              <p>
                <strong>Third Party Vendors And Their Privacy Policies:</strong>{" "}
                We use and partnered up with some third party to run our Website
                and provide our Service to you. They uses technologies like
                cookies, JavaScripts, or pixel tags that are used in their
                respective services and links that appear on Codioful, which are
                sent directly to your browser. They automatically receive your
                IP address when this occurs. These technologies are used to
                measure the effectiveness of their service such as advertising
                campaigns and/or to personalize the advertising content that you
                see on websites that you visit. Each of our partners has their
                own Privacy Policy for their policies on user data. Codioful has
                no access to or control over these cookies that are used by
                third party vendors. For more information about their cookies
                and other technologies, we described the basics and hyperlinked
                to their detailed Privacy Policies below.
              </p>
            </ThinContainer>
          </div>
          <div className="privacy-partner">
            <h2>Why We Collect And Use Your Information</h2>
            <ThinContainer>
              <p>
                We collect informations and use them in variety of ways. We
                already mentioned above how we collect informations and describe
                below about the purpose of collection and how we use the
                collection.
              </p>
              <h3>Why We Collect And Use Your Information</h3>
              <p>
                <strong>Future Improvments And Developments:</strong> To further
                develop and improve our Services–for example, by adding new
                features that we think our users will enjoy or will help them to
                create and manage their own projects or services more
                efficiently; To monitor and analyze trends and better understand
                how users interact with our Services, which helps us improve our
                Services and make them easier to use;
              </p>
              <p>
                <strong>Impact of Advertising:</strong> To measure, gauge, and
                improve the effectiveness of our advertising, and better
                understand user retention and attrition — for example, we may
                analyze how many individuals accessed our Service after
                receiving a marketing message or the features used by those who
                continue to use our Services after a certain length of time;
              </p>
              <p>
                <strong>Protecting Our Service:</strong> To monitor and prevent
                any problems with our Services, protect the security of our
                Services, detect and prevent fraudulent transactions and other
                illegal activities, fight spam, and protect the rights and
                property of Automattic and others, which may result in us
                declining a transaction or the use of our Services; To monitor
                the usage of the Service, To detect, prevent and address
                technical issues
              </p>
              <p>
                <strong>Communicate With You:</strong> For example, we may email
                you to solicit your feedback, share tips for getting the most
                out of our products, or keep you up to date on Automattic; text
                you to verify your payment; or call you to share offers and
                promotions that we think will be of interest to you. If you
                don’t want to hear from us, you can opt out of marketing
                communications at any time. (Please keep in mind that even if
                you opt out, we’ll still send you important updates relating to
                your account); To notify you about changes to our service, even
                if we change this privacy policy. To provide customer care and
                support
              </p>
              <p>
                <strong>To personalize your experience:</strong> using our
                Services, provide content recommendations (for example, through
                our Reader Post Suggestions), target our marketing messages to
                groups of our users (for example, those who have a particular
                plan with us or have been our user for a certain length of
                time), and serve relevant advertisements. To allow you to
                participate in interactive features of our Service when you
                choose to do so
              </p>
              <p>
                <strong>
                  Legal Bases for Collecting and Using Information:
                </strong>{" "}
                A note here for those in the European Union about our legal
                grounds for processing information about you under EU data
                protection laws, which is that our use of your information is
                based on the grounds that:
              </p>
              <p>
                (1) The use is necessary in order to fulfill our commitments to
                you under the applicable terms of service or other agreements
                with you or is necessary to administer your account — for
                example, in order to enable access to our website on your device
                or charge you for a paid plan; or
              </p>
              <p>
                (2) The use is necessary for compliance with a legal obligation;
                or
              </p>
              <p>
                (3) The use is necessary in order to protect your vital
                interests or those of another person; or
              </p>
              <p>
                (4) We have a legitimate interest in using your information —
                for example, to provide and update our Services; to improve our
                Services so that we can offer you an even better user
                experience; to safeguard our Services; to communicate with you;
                to measure, gauge, and improve the effectiveness of our
                advertising; and to understand our user retention and attrition;
                to monitor and prevent any problems with our Services; and to
                personalize your experience; or
              </p>
              <p>
                (5) You have given us your consent — for example before we place
                certain cookies on your device and access and analyze them later
                on, as described in our Cookie Policy.
              </p>
            </ThinContainer>
          </div>
          <div className="privacy-how-info-share">
            <h2>Why And How We Share Your Information</h2>
            <ThinContainer>
              <p>
                <strong>
                  <i>We do not sell our users’ private personal information.</i>
                </strong>{" "}
                We share information about you in the limited circumstances
                spelled out below and with appropriate safeguards on your
                privacy.
              </p>
              <p>
                <strong>
                  Subsidiaries, Employees, And Independent Contractors:
                </strong>{" "}
                We may disclose information about you to our subsidiaries, our
                employees, and individuals who are our independent contractors
                that need to know the information in order to help us provide
                our Service or to process the information on our behalf. We
                require our subsidiaries, employees, and independent contractors
                to follow this Privacy Policy for personal information that we
                share with them.
              </p>
              <p>
                <strong>Third Party Vendors:</strong> We may share information
                about you with third party vendors who need to know information
                about you in order to provide their services to us, or to
                provide their services to you. This group includes vendors that
                help us provide our Services to you (like postal and email
                delivery services that help us stay in touch with you, send
                email campaigns to let you know about new updates, deals, policy
                changes, customer chat and email support services that help us
                communicate with you), those that assist us with our marketing
                efforts (e.g. by providing tools for identifying a specific
                marketing target group or improving our marketing campaigns),
                those that help us understand and enhance our Services (like
                analytics providers), who may need information about you in
                order to, for example, provide technical or other support
                services to you. We require vendors to agree to privacy
                commitments in order to share information with them.
              </p>
              <p>
                <strong>Legal and Regulatory Requirements:</strong> We may
                disclose information about you in response to a subpoena, court
                order, or other governmental request.
              </p>
              <p>
                <strong>To Protect Rights, Property, And Others:</strong> We may
                disclose information about you when we believe in good faith
                that disclosure is reasonably necessary to protect the property
                or rights of Codioful, third parties, or the public at large.
                For example, if we have a good faith belief that there is an
                imminent danger of death or serious physical injury, we may
                disclose information related to the emergency without delay.
              </p>
              <p>
                <strong>Business Transfers:</strong> In connection with any
                merger, sale of company assets, or acquisition of all or a
                portion of our business by another company, or in the unlikely
                event that Codioful goes out of business or enters bankruptcy,
                user information would likely be one of the assets that is
                transferred or acquired by a third party. If any of these events
                were to happen, this Privacy Policy would continue to apply to
                your information and the party receiving your information may
                continue to use your information, but only consistent with this
                Privacy Policy.
              </p>
              <p>
                <strong>Aggregated or De-Identified Information:</strong> We may
                share information that has been aggregated or reasonably
                de-identified, so that the information could not reasonably be
                used to identify you. For instance, we may publish aggregate
                statistics about the use of our Services and we may share a
                hashed version of your email address to facilitate customized ad
                campaigns on other platforms.
              </p>
              <p>
                <strong>Published Support Requests:</strong> And if you send us
                a request (for example, via a support email or one of our
                feedback mechanisms), we reserve the right to publish that
                request in order to help us clarify or respond to your request
                or to help us support other users.
              </p>
            </ThinContainer>
          </div>
          <div className="privacy-safe">
            <h2>How Long We Keep Your Information And Secure It</h2>
            <ThinContainer>
              <p>
                We generally discard information about you when we no longer
                need the information for the purposes for which we collect and
                use it, and we are not legally required to continue to keep it.
              </p>
              <p>
                For example, we keep the web server logs that record information
                about a visitor to Codioful Website, such as the visitor’s IP
                address, browser type, and operating system, for approximately
                30 days. We retain the logs for this period of time in order to,
                among other things, analyze traffic to Codioful Website and
                investigate issues if something goes wrong on our Website.
              </p>
              <p>
                Some other informations may be collected and stored in order to
                keep in touch with you such as making businesses or sending
                updates to you until you request us to delete your information
                or unsubscribe from our emails.
              </p>
              <p>
                No online service, method of transmission over the internet or
                method of electronic storage is 100% secure, we can not
                guarantee its absolute security. We work very hard, and strive,
                to protect information about you against unauthorized access,
                use, alteration, or destruction, and take reasonable measures to
                do so, such as monitoring our Service for potential
                vulnerabilities and attacks.
              </p>
            </ThinContainer>
          </div>
          <div className="privacy-rights">
            <h2>What Is Your Choices And Rights About Your Information</h2>
            <ThinContainer>
              <p>
                There are several choices and rights for you to take control of
                your information. We list them in details below.
              </p>
              <h3>Choices You Can Make About You</h3>
              <p>
                <strong>
                  Limit Access to Information on Your Mobile Device:
                </strong>{" "}
                Your mobile device operating system should provide you with the
                ability to discontinue our ability to collect stored information
                or location information via our mobile apps.{" "}
              </p>
              <p>
                <strong>Opt-Out of Marketing Communications:</strong> You may
                opt out of receiving promotional communications from us. Just
                follow the instructions in those communications or let us know.
                If you opt out of promotional communications, we may still send
                you other communications, such as support email if you contact
                us for support.
              </p>
              <p>
                <strong>Set Your Browser to Reject Cookies:</strong> You can
                usually choose to set your browser to remove or reject browser
                cookies before using our Site, with the drawback that certain
                features of our Site may not function properly without the aid
                of cookies. You can choose to disable cookies through your
                individual browser options. To know more detailed information
                about cookie management with specific web browsers, it can be
                found at the browsers' respective websites.
              </p>
              <h3>What is Your Rights About Your Information</h3>
              <p>
                If you are located in certain countries, including those that
                fall under the scope of the European General Data Protection
                Regulation (as known as the “GDPR”), data protection laws give
                you rights with respect to your personal data, subject to any
                exemptions provided by the law, including the rights to:
              </p>

              <p>
                Request access to your personal data;
                <br />
                Request correction or deletion of your personal data;
                <br />
                Object to our use and processing of your personal data;
                <br />
                Request that we limit our use and processing of your personal
                data; and
                <br />
                Request portability of your personal data.
              </p>

              <p>
                You can usually access, correct, or delete your personal data by
                emailing us to code@codioful.com
              </p>

              <p>
                EU individuals also have the right to make a complaint to a
                government supervisory authority.
              </p>

              <p>
                <strong>How to Reach Us:</strong> If you have a question about
                this Privacy Policy, or you would like to contact us about any
                of the rights mentioned in the Your Rights section above, please
                contact us.
              </p>
            </ThinContainer>
          </div>
          <div className="privacy-others">
            <h2>Transferring And Handling Your Information</h2>
            <ThinContainer>
              <p>
                <strong>If you live and/or use our Service from EU:</strong> As
                our Service is offered worldwide, the information about you that
                we process when you use the Service in the EU may be used,
                stored, and/or accessed by individuals operating outside the
                European Economic Area (EEA) who work for us, other members of
                our group of companies, or third party data processors. This is
                required for the purposes listed in the Why We Collect And Use
                Your Information section above. When providing information about
                you to entities outside the EEA, we will take appropriate
                measures to ensure that the recipient protects your personal
                information adequately in accordance with this Privacy Policy as
                required by applicable law. These measures include:
              </p>
              <p>
                In the case of US based entities, entering into European
                Commission approved standard contractual arrangements with them,
                or ensuring they have signed up to the EU-US Privacy Shield; or
                In the case of entities based in other countries outside the
                EEA, entering into European Commission approved standard
                contractual arrangements with them.
              </p>
              <p>
                You can ask us for more information about the steps we take to
                protect your personal information when transferring it from the
                EU.
              </p>
              <p>
                <strong>
                  If you live and/or use our Service from outside EU:
                </strong>{" "}
                Your information, including Personal Data, may be transferred to
                - and maintained on - computers located outside of your state,
                province, country or other governmental jurisdiction where the
                data protection laws may differ than those from your
                jurisdiction.
              </p>
              <p>
                If you are located outside Bangladesh and US, and choose to
                provide information to us, please note that we transfer and
                store the data, including Personal Data, to US server, and
                access, process, maintain it remotely from Bangladesh.
              </p>
              <p>
                Your consent to this Privacy Policy followed by your submission
                of such information represents your agreement to that transfer
                and maintenance.
              </p>
              <p>
                Codioful will take all steps reasonably necessary to ensure that
                your data is treated securely and in accordance with this
                Privacy Policy and no transfer of your Personal Data will take
                place to an organization or a country unless there are adequate
                controls in place including the security of your data and other
                personal information.
              </p>
            </ThinContainer>
          </div>
          <div className="privacy-other">
            <h2>Other Things You Should Know</h2>
            <ThinContainer>
              <h3>Children's Information</h3>
              <p>
                Another part of our priority is adding protection for children
                while using the internet. We encourage parents and guardians to
                observe, participate in, and/or monitor and guide their online
                activity.
              </p>
              <p>
                Codioful does not knowingly collect any Personal Identifiable
                Information from children under the age of 13. If you think that
                your child provided this kind of information on our website, we
                strongly encourage you to contact us immediately and we will do
                our best efforts to promptly remove such information from our
                records.
              </p>
              <h3>Ads And Analytics Services By Others</h3>
              <p>
                Ads appearing on any of our Services may be delivered by
                advertising networks. Other parties may also provide analytics
                services via our Services. These ad networks and analytics
                providers may set tracking technologies (like cookies) to
                collect information about your use of our Services and across
                other websites and online services. These technologies allow
                these third parties to recognize your device to compile
                information about you or others who use your device. This
                information allows us and other companies to, among other
                things, analyze and track usage, determine the popularity of
                certain content, and deliver advertisements that may be more
                targeted to your interests. Please note this Privacy Policy only
                covers the collection of information by Codioful and does not
                cover the collection of information by any third party
                advertisers or analytics providers.
              </p>
              <h3>Third Party Services And Their Privacy Policy</h3>
              <p>
                <strong>Google DoubleClick DART Cookie:</strong> Google is one
                of a third party vendor on our site. It also uses cookies, known
                as DART cookies, to serve ads to our site visitors based upon
                their visit to www.google.com and other sites on the internet.
                However, visitors may choose to decline the use of DART cookies
                by visiting the Google ad and content network Privacy Policy at
                the following URL – https://policies.google.com/technologies/ads
              </p>
              <p>
                <strong>Google Analytics:</strong> We use analytics service
                through Google Analytics to track our Site and Service users
                such as which country people visited our Site, how they find us,
                what pages they access, how long they access, when (time and
                date) they access and other related analytics data. Visit their
                detailed Privacy Policy here - https://policies.google.com
              </p>
              <p>
                <strong>Send Grid:</strong> We use SendGrid to collect and send
                email campaigns to our subscribers. We store the data on their
                servers so if you opt-out from any of our email, your
                information such as email address will automatically deleted
                from our subscription list. We encourage you to visit their
                Privacy Policy here -
                https://sendgrid.com/policies/privacy/services-privacy-policy/
              </p>
              <p>
                <strong>Links to Other Sites:</strong> Our Service may contain
                links to other sites that are not operated by us. If you click a
                third party link, you will be directed to that third party's
                site. We strongly advise you to review the Privacy Policy of
                every site you visit.
              </p>
              <p>
                We have no control over and assume no responsibility for the
                content, privacy policies or practices of any third party sites
                or services.
              </p>
              <h3>Online Privacy Policy Only</h3>
              <p>
                This Privacy Policy applies only to our online activities and is
                valid for visitors to our website with regards to the
                information that they shared and/or collect in Codioful. This
                policy is not applicable to any information collected offline or
                via channels other than this website.
              </p>
              <h3>Privacy Policy Changes</h3>
              <p>
                Although most changes are likely to be minor, Codioful may
                change its Privacy Policy from time to time. Codioful encourages
                visitors to frequently check this page for any changes to its
                Privacy Policy. If we make changes, we will notify you by
                revising the change log below, and, in some cases, we may
                provide additional notice (such as adding a statement to our
                homepage or sending you a notification through email). Your
                further use of the Services after a change to our Privacy Policy
                will be subject to the updated policy.
              </p>
            </ThinContainer>
          </div>
          <div className="privacy-action">
            <ThinContainer>
              <h3>Consent</h3>
              <p>
                By using our website, you hereby consent to our Privacy Policy
                and agree to its Terms and Conditions.
              </p>
            </ThinContainer>
          </div>
          <div className="changelog">
            <ThinContainer>
              <h3>Changelog</h3>
              <p>11 Dec, 2019 - Initial Privacy Policy in place</p>
            </ThinContainer>
          </div>
        </div>
      </Sheet>
      <Subscription />
    </Container>
  </Layout>
)
